import React from "react";
import { graphql } from "gatsby";
import StandardPage from "../components/StandardPage";
import Layout from "../components/layout";

export default ({ data }) => {
  const {
    allStrapiPages: {
      edges: {
        0: { node: page }
      }
    }
  } = data;
  return (
    <Layout title={page.Pagetitle} description={page.Content.substr(0, 70)}>
      <StandardPage title={page.Pagetitle} content={page.Content} />
    </Layout>
  );
};
export const query = graphql`
  query($strapiId: Int!) {
    allStrapiPages(filter: { strapiId: { eq: $strapiId } }) {
      edges {
        node {
          Pagetitle
          Content
        }
      }
    }
  }
`;
