import React from "react";
import styled from "styled-components";
import { PageTitle, Content } from "../globals/style";

const Wrapper = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: 8em auto;
  @media screen and (max-width: 991px) {
    margin: 3em auto;
    padding: 5%;
  }
`;
const StyledContent = styled(Content)`
  margin: 3em auto;
  @media screen and (max-width: 991px) {
    margin: 3em auto;
    padding: 0;
  }
`;
const StandardPage = ({ title, content }) => (
  <Wrapper>
    <PageTitle>{title}</PageTitle>
    <StyledContent>{content}</StyledContent>
  </Wrapper>
);

export default StandardPage;
